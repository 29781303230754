<template>
  <div :class="{ 'border-danger show-border': hasError }">
    <ckeditor :editor="editor" v-model="html" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'
import '@ckeditor/ckeditor5-build-classic/build/translations/fr'

export default {
  mixins: [inputMixin],
  watch: {
    value: {
      handler (html) {
        this.html = html !== null ? html : ''
      },
      immediate: true
    },
    html: {
      handler (value) {
        let data = null

        if (value && value.replace(/<[^>]*>?/gm, '')) {
          data = value
        }

        this.$emit('value-change', data)
      },
      immediate: true
    }
  },
  data () {
    return {
      editor: ClassicEditor,
      html: '',
      editorConfig: {
        language: 'fr',
        toolbar: {
          items: ['heading', 'bold', 'italic', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList', 'link'],
          shouldNotGroupWhenFull: true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.show-border {
  border: 1px solid;
}
</style>
